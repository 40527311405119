<template>
  <div class="dashboard fade-up">
    <div class="dashboard-table" v-loading="isLoading">
      <div class="dashboard-table__header">
        <div
          class="dashboard-table__header-actions"
          :class="{ visible: multipleSelection.length }"
        >
          <div class="selected">{{ multipleSelection.length }} танланган</div>
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="deleteSelectedVideo"
          >
            Ўчириш
          </el-button>
        </div>
        <div class="left">
          <el-input
            placeholder="Номи буйича излаш"
            prefix-icon="el-icon-search"
            v-model="search"
          />
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="$router.push('/videos/new')"
          >
            Янги қушиш
          </el-button>
        </div>
      </div>
      <div class="dashboard-table__body">
        <el-table
          ref="dashboardTable"
          style="width: 100%"
          :data="videosData"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            prop="publish_at"
            label="Нашр вақти"
            width="150"
            sortable
          >
            <template slot-scope="scope">
              {{ $date(scope.row.publish_at).format("HH:mm | DD.MM.YYYY") }}
            </template>
          </el-table-column>
          <el-table-column
            prop="updated_at"
            label="Тахрирлаш вақти"
            width="180"
            sortable
          >
            <template slot-scope="scope">
              {{ $date(scope.row.updated_at).format("HH:mm | DD.MM.YYYY") }}
            </template>
          </el-table-column>
          <el-table-column prop="title_uz" label="Номи"></el-table-column>
          <el-table-column prop="status" label="Статус" width="100">
            <template slot-scope="scope">
              <el-tag
                size="medium"
                :type="scope.row.status === 'published' ? 'success' : 'warning'"
              >
                {{ scope.row.status }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="Амаллар" width="120">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-edit"
                @click.stop="$router.push('/videos/edit/' + scope.row.id)"
              />
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click.stop="deleteVideo(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="dashboard-table__footer">
        <el-pagination
          :disabled="isLoading"
          background
          layout="prev, pager, next"
          :page-size="perPage"
          :total="totalCount"
          :current-page.sync="currentPage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data: () => ({
    perPage: 10,
    currentPage: 1,
    isLoading: false,
    search: "",
    multipleSelection: []
  }),
  watch: {
    currentPage() {
      if (this.isLoading) return;

      this.fetchNews();
    },
    search: _.debounce(function() {
      this.isLoading = true;
      this.currentPage = 1;
      this.fetchNews();
    }, 500)
  },
  computed: {
    totalCount() {
      return this.$store.state.videoModule.videosCount;
    },
    videosData() {
      return this.$store.state.videoModule.videos;
    }
  },
  created() {
    const loading = this.$loading({
      lock: true
    });
    Promise.all([
      this.$store.dispatch("fetchVideos", {
        _start: this.currentPage * this.perPage - this.perPage,
        _limit: this.perPage
      }),
      this.$store.dispatch("fetchVideosCount")
    ]).finally(() => {
      loading.close();
    });
  },
  methods: {
    fetchNews() {
      this.isLoading = true;
      Promise.all([
        this.$store.dispatch("fetchVideosCount", {
          title_uz_contains: this.search
        }),
        this.$store.dispatch("fetchVideos", {
          _start: this.currentPage * this.perPage - this.perPage,
          _limit: this.perPage,
          title_uz_contains: this.search
        })
      ]).finally(() => {
        this.isLoading = false;
      });
    },
    deleteVideo(row) {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          this.$store
            .dispatch("deleteVideo", row.id)
            .then(() => {
              if (this.videosData.length === 0) {
                this.isLoading = false;
                this.fetchNews();
              }
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    },
    deleteSelectedVideo() {
      this.$confirm("Ўчириш амалини тасдиқлайсизми?", "Диққат!", {
        confirmButtonText: "Ха",
        cancelButtonText: "Бекор қилиш",
        type: "warning"
      })
        .then(() => {
          this.isLoading = true;
          Promise.all(
            this.multipleSelection.map(r => {
              this.$store.dispatch("deleteVideo", r.id);
            })
          )
            .then(() => {
              this.$message({
                type: "success",
                message: "Ўчирилди"
              });
            })
            .finally(() => (this.isLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Бекор қилинди"
          });
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.dashboard {
  padding: 20px;
}

.card {
  border-radius: 10px;
  color: #909399;
  &__inner {
    display: flex;
    align-items: center;
    transition: 0.2s all;
  }

  &--success {
    color: #67c23a;
    // background-color: #f0f9eb;
    // border: 1px solid #e1f3d8;
  }

  &--warning {
    color: #e6a23c;
    // background-color: #fdf6ec;
    // border: 1px solid #faecd8;
  }

  &:hover {
    .card__inner {
      color: #409eff;
    }
  }

  &__icon {
    font-size: 86px;
    font-weight: bold;
    margin-right: 25px;
    line-height: 1.2;
  }

  &__content {
    line-height: 1.1;
  }

  &__name {
    font-size: 14px;
    margin-top: 4px;
  }

  &__count {
    font-size: 56px;
    font-weight: bold;
  }
}
</style>
